<section id="header" class="header w-100 sticky-top solidheader">
  <div id="div_theme" class="themeInfo theme-info-wrapper" *ngIf="isPASUser">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-sm-8 mobile-width-pas-text">
          <div class="themeInfoText">
            Tokio Marine Travel Insurance Policy Administration System
          </div>
        </div>
        <div class="col-sm-4 mobile-width-pas-button">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="text-md-end">
                <span class="number text-md-end agentName">
                  {{ agentName }}
                  <a id="PasUrl" (click)="clearSession()"
                    >← BACK TO PAS</a
                  ></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="container-fluid borderonscroll d-none d-lg-block"
    [ngClass]="{ 'pasuser-header-number': isPASUser }"
  >
    <div class="header-top pt-1 pb-1">
      <div class="container-xl">
        <div class="row justify-content-center justify-content-lg-end">
          <div
            class="col text-end pe-3"
            (click)="sendAdobeHeaderClickEvent('13 13 29')"
          >
            <img class="call-icon" src="assets/images/racv/call-light.png" />
            <span class="number text-primary me-0"
              ><a href="tel:131329" class="text-primary">13 13 29</a></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="container-fluid shadowonscroll"
    [ngClass]="{ 'pasuser-header': isPASUser }"
  >
    <div class="main-header">
      <div class="row">
        <nav
          class="navbar navbar-expand-lg p-0 bsb-navbar bsb-navbar-hover bsb-navbar-caret"
        >
          <div class="container-xl navwithbg p-0 pe-0">
            <a
              class="navbar-brand"
              href=""
              (click)="sendAdobeHeaderClickEvent('racv-logo')"
              ><img
                class="mainlogo ps-2 ps-md-0 pt-2 pt-md-0"
                src="assets/images/racv/svg-logo.png"
            /></a>

            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              (click)="sendAdobeHeaderClickEvent('menu')"
            >
              <span class="navbar-toggler-icon"
                ><img src="assets/images/racv/menu.png"
              /></span>
            </button>

            <div
              class="offcanvas offcanvas-start align-items-lg-end"
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div class="offcanvas-header border-bottom">
                <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                  <a
                    class="navbar-brand"
                    href=""
                    (click)="sendAdobeHeaderClickEvent('racv-logo')"
                    ><img
                      class="mainlogo"
                      src="assets/images/racv/svg-logo.png"
                  /></a>
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body">
                <ul class="navbar-nav">
                  <li class="nav-item px-2">
                    <a
                      class="nav-link"
                      target="_blank"
                      href="travel-alerts"
                      (click)="sendAdobeHeaderClickEvent('Travel Alerts')"
                      >Travel Alerts</a
                    >
                  </li>
                  <li class="nav-item px-2">
                    <a
                      class="nav-link"
                      target="_blank"
                      href="faqs"
                      (click)="sendAdobeHeaderClickEvent('FAQs')"
                      >FAQs</a
                    >
                  </li>
                  <li class="nav-item px-2">
                    <a
                      class="nav-link"
                      target="_blank"
                      href="{{ pdsLink }}"
                      (click)="
                        sendAdobeHeaderClickEvent(
                          'Product Disclosure Statement'
                        )
                      "
                      >Product Disclosure Statement</a
                    >
                  </li>
                  <li class="nav-item px-2">
                    <a
                      class="nav-link"
                      target="_blank"
                      href="how-to-claim"
                      (click)="sendAdobeHeaderClickEvent('How to Claim')"
                      >How to Claim</a
                    >
                  </li>
                  <li class="nav-item px-2">
                    <a
                      class="nav-link"
                      target="_blank"
                      href="cruise"
                      (click)="sendAdobeHeaderClickEvent('Cruise')"
                      >Cruise</a
                    >
                  </li>
                </ul>
              </div>
              <!-- Visible only in mobile menu -->
              <div
                class="offcanvas-footer offcanvas-header border-top flex-column text-center"
              >
                <div class="col-12">
                  <a
                    href="tel:131329"
                    (click)="sendAdobeHeaderClickEvent('13 13 29')"
                    ><i class="fa-solid fa-phone"></i>
                    <span class="fw-semibold">13 13 29</span></a
                  >
                </div>
                <div class="col-12"></div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</section>
