<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title py-5">
    <div class="page-title-overlay"></div>
    <!-- <img src="assets/images/racv/page-header/privacy-policy.jpg" alt="" class="w-100 page-title-image" /> -->
    <div class="page-header-title-caption">
      <!-- <h1>Tokio Marine Australasia Privacy Policy</h1> -->
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5 mt-3">
    <div class="container page-content-info">
      <h3>Privacy Policy</h3>
      <ul class="link">
        <li>
          <a href="https://www.tokiomarine.com.au/wp-content/uploads/2023/09/Privacy-Policy-2023.pdf" target="_blank">Tokio Marine &amp;
            Nichido Fire Insurance Co., Ltd. Privacy Policy</a>
        </li>
        <li>
          <a href="https://www.racv.com.au/about-racv/corporate-governance/legal.html" target="_blank">Royal Automobile Club of Victoria
            Privacy Policy</a>
        </li>
      </ul>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>