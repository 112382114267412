import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, Router } from '@angular/router';
import { Observable, Subject, Subscription, debounceTime, of } from 'rxjs';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  FormControl,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { CountryService } from '../../services/country.service';
import { WordingService } from '../../services/wording.service';
import { ThemeService } from '../../services/theme.service';
import { ValidationService } from '../../services/validation.service';
import { LocalCacheService } from '../../services/localCache.service';
import { PromotionService } from '../../services/promotion.service';
import { QuoteService } from '../../services/quote.service';
import { JsonDataService } from '../../services/json-data.service';

//import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { MessagesModule } from 'primeng/messages';
import { Rule } from '../../interfaces/rule';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { stateList } from '../../utils/stateList';
import moment, { Moment } from 'moment';
import { TitleCasePipe } from '../../utils/title-case.pipe';
import { FloatLabelModule } from 'primeng/floatlabel';
import { environment } from '../../../environments/environment';
import { TooltipModule } from 'primeng/tooltip';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PDSService } from '../../services/pds.service';

@Component({
  selector: 'app-static-search',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    MatAutocompleteModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    InputSwitchModule,
    FormsModule,
    MessagesModule,
    MultiSelectModule,
    CalendarModule,
    InputTextModule,
    KeyFilterModule,
    FloatLabelModule,
    TooltipModule,
    NgSelectModule,
  ],
  providers: [TitleCasePipe],
  templateUrl: './static-search.component.html',
  styleUrl: './static-search.component.scss',
})

// LABEL - variable declarations
export class StaticSearchComponent implements OnInit, AfterViewInit {
  subscriptions: Subscription = new Subscription();
  singleTripForm: FormGroup = new FormGroup({});
  multiTripForm: FormGroup = new FormGroup({});
  groupedCountries: any[] = [];
  stateList: any[] = [];
  stateForm = this._formBuilder.group({
    stateGroup: '',
  });
  getaquotewording: string | undefined;
  checked = true;
  messages: any[] = [];
  primaryAgeMessage: string = '';
  secondaryAgeMessage: string = '';
  errorMessages: any = {};
  validationsList: any[] = [];
  activatedClub: string = '';
  selectedCountries: any = [];
  groupedAllCountries: any[] = [];
  age1: any = '';
  age2: any = '';
  dependentsCount: any = '';
  state: any = null;
  promoCode: any = null;
  departureDate: any = null;
  returnDate: any = null;
  returnDateMulti: any = null;
  minReturnDate: Date = new Date();
  maxReturnDate: any;
  maxDepartDate: Date = new Date();
  minDepartDate: Date = new Date();
  minReturnDateMulti: Date = new Date();
  maxReturnDateMulti: any;
  countriesNotAllowedToTravel: any = [];
  promoCodeMessage: string = '';
  isSingleTrip: boolean = true;
  userSelectedReturn: boolean = false;
  showErrorAge1: any = false;
  showErrorAge2: any = false;
  showErrorAge1Multi: any = false;
  showErrorAge2Multi: any = false;
  defaultReturnDateValueIncrement: number = 0;
  firstMinAge: number = 0;
  firstMaxAge: number = 0;
  secondMinAge: number = 0;
  secondMaxAge: number = 0;
  firstMinAgeMulti: number = 0;
  firstMaxAgeMulti: number = 0;
  secondMinAgeMulti: number = 0;
  secondMaxAgeMulti: number = 0;
  cautionCountryMessage: any;
  cannotTravelDomesticInternationalMessage: any;
  cannotSelectDomesticAMTW2CMessage: any;
  travelDestinationRequiredMessage: any;
  invalidInputDepartMessage: any;
  departDateMoreThan18MonthsMessage: any;
  departDateBeforeTodayMessage: any;
  departDateRequiredMessage: any;
  invalidInputReturnMultiMessage: any;
  invalidInputReturnMessage: any;
  returnDateMoreThan365DaysMessage: any;
  returnDateBeforeDepartMessage: any;
  returnDateMoreThan60DaysMessage: any;
  returnBeforeDepartDateMultiMessage: any;
  datesEqualMultiMessage: any;
  returnBeforeDepartDateMessage: any;
  returnGreaterThanOneYearMessage: any;
  datesEqualMessage: any;
  returnRequiredMessage: any;
  stateRequiredMessage: any;
  maxDepartAMT: number = 60;
  maxReturnYear: number = 1;
  maxDepartDays: number = 548;
  isTooltipVisible: boolean = false;
  isTooltipVisibleTravDest: boolean = false;
  isTooltipVisibleDep: boolean = false;

  sanitizedHtml: SafeHtml | null = null;
  pdsLink: string = '';
  allCountries: any[] = [];
  ngSelectIsFocused: boolean = false;
  showRequiredReturnDateMultiError: boolean = false;
  searchTerms: any;

  constructor(
    private countryService: CountryService,
    private _formBuilder: FormBuilder,
    private wordingService: WordingService,
    private themeService: ThemeService,
    private validationService: ValidationService,
    private localCacheService: LocalCacheService,
    private quoteService: QuoteService,
    private promotionService: PromotionService,
    private jsonDataService: JsonDataService,
    private router: Router,
    private titleCasePipe: TitleCasePipe,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private pdsService: PDSService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.updateTooltipVisibility();
    this.activatedClub = environment.autoClub; //TO DO: CHANGE TO DYNAMIC HANDLING
    this.loadValidations();
    this.loadWordings();

    this.subscriptions.add(
      this.countryService.getCountries().subscribe((countries) => {
        this.allCountries = countries;
      }),
    );
    this.stateList = stateList;
    this.setDepartRange();
    this.createForm();
    if (this.departureDate) {
      this.setReturnDateAndRange();
    }

    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');

    if (this.departureDate) {
      if (quoteEnquiry && quoteEnquiry.return) {
        this.returnDate = new Date(quoteEnquiry.return);
        this.maxReturnDate = new Date(this.departureDate);
        this.maxReturnDate.setFullYear(
          this.maxReturnDate.getUTCFullYear() + this.maxReturnYear,
        );
        this.maxReturnDate = new Date(
          this.maxReturnDate.getUTCFullYear(),
          this.maxReturnDate.getUTCMonth(),
          this.maxReturnDate.getUTCDate(),
        );

        const sydneyDate = new Date(
          new Intl.DateTimeFormat('en-US', {
            timeZone: 'Australia/Sydney',
          }).format(new Date()),
        );
        this.minReturnDate = new Date(
          sydneyDate.getFullYear(),
          sydneyDate.getMonth(),
          sydneyDate.getDate(),
        );

        this.maxReturnDateMulti = new Date(this.departureDate);
        this.maxReturnDateMulti.setFullYear(
          this.maxReturnDateMulti.getFullYear() + 1,
        );
        this.maxReturnDateMulti = new Date(
          this.maxReturnDateMulti.getFullYear(),
          this.maxReturnDateMulti.getMonth(),
          this.maxReturnDateMulti.getDate(),
        );

        this.minReturnDateMulti = new Date(
          this.departureDate.getFullYear(),
          this.departureDate.getMonth(),
          this.departureDate.getDate(),
        );
      } else {
        this.setReturnDateAndRange();
      }
    }

    if (quoteEnquiry) {
      this.isSingleTrip = quoteEnquiry.isSingleTrip;
      const tripType = this.isSingleTrip ? 'Single' : 'Multiple';
      this.multiTripForm.patchValue({
        isSingleTrip: this.isSingleTrip,
        tripType: tripType,
      });
      this.singleTripForm.patchValue({
        isSingleTrip: this.isSingleTrip,
        tripType: tripType,
      });
    }

    // setup debouncer allow time for user fill out fields before triggering error message
    this.setupFieldValidationTimer(this.singleTripForm, true);
    this.setupFieldValidationTimer(this.multiTripForm, false);
  }

  ngAfterViewInit() {
    this.addCustomPlaceHolder();
  }

  addCustomPlaceHolder() {
    const targetElements = this.elRef.nativeElement.querySelectorAll(
      '.p-multiselect-label-container',
    );
    const multiSelectLabels = this.elRef.nativeElement.querySelectorAll(
      '.p-multiselect-label',
    );

    targetElements.forEach((targetElement: any, index: number) => {
      const existingDiv = targetElement.querySelector('.custom-placeholder');
      const multiSelectLabel = multiSelectLabels[index];

      if (this.selectedCountries && this.selectedCountries.length > 0) {
        if (!existingDiv) {
          const newDiv = this.renderer.createElement('div');
          const text = this.renderer.createText('Travel Destination *');

          this.renderer.appendChild(newDiv, text);
          this.renderer.addClass(newDiv, 'custom-placeholder'); // Add the CSS class

          this.renderer.appendChild(targetElement, newDiv);
        } else {
          this.renderer.removeClass(existingDiv, 'hidden');
        }

        if (multiSelectLabel) {
          this.renderer.removeClass(multiSelectLabel, 'reset');
          this.renderer.setStyle(multiSelectLabel, 'margin', '15px 0 0 0');
        }
      } else {
        if (existingDiv) {
          this.renderer.addClass(existingDiv, 'hidden');
        }

        if (multiSelectLabel) {
          this.renderer.addClass(multiSelectLabel, 'reset');
          this.renderer.setStyle(multiSelectLabel, 'margin', 'auto 0');
        }
      }
    });
  }

  addSelectedOption(event: any) {
    if (event) {
      this.singleTripForm.value.travelDestination.push(
        new FormControl(event.value),
      );
    }
  }

  createForm() {
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry') || {};
    this.selectedCountries = quoteEnquiry.travelDestination;

    if (quoteEnquiry.depart) {
      this.departureDate = new Date(quoteEnquiry.depart);
    }

    if (quoteEnquiry.return) {
      this.returnDate = new Date(quoteEnquiry.return);
    }

    // If there's values in session use it as default
    this.age1 = quoteEnquiry.age1 ?? '';
    this.age2 = quoteEnquiry.age2 ?? '';
    this.dependentsCount = quoteEnquiry.dependentsCount ?? 0;
    this.state = quoteEnquiry.state?.id ?? '';
    this.promoCode = quoteEnquiry.promoCode ?? '';
    // initial load and no data
    if (this.state === '' && this.activatedClub === 'RACV') {
      const racvDefaultState = this.stateList.filter(
        (x) => x.code === 'VIC',
      )[0];
      if (racvDefaultState) {
        this.state = racvDefaultState.id;
      }
    }
    this.singleTripForm = this._formBuilder.group(
      {
        travelDestination: [
          [],
          [
            Validators.required,
            this.cannotTravelValidator(),
            this.cannotTravelDomesticInternational(),
          ],
        ],
        depart: [[], [Validators.required]],
        return: [[], [Validators.required]],
        age1: ['', [Validators.required]],
        age2: ['', []],
        dependentsCount: ['', []],
        state: ['', [Validators.required]],
        promoCode: ['', []],
        tripType: ['Single'],
        isSingleTrip: [true],
      },
      {
        validators: [this.selectReturnDate('depart', 'return', 'isSingleTrip')],
      },
    );

    this.multiTripForm = this._formBuilder.group(
      {
        travelDestination: [[], [Validators.required]],
        depart: ['', [Validators.required]],
        return: ['', [Validators.required]],
        age1: ['', [Validators.required]],
        age2: ['', []],
        dependentsCount: ['', []],
        state: ['', [Validators.required]],
        promoCode: ['', []],
        tripType: ['Multiple'],
        isSingleTrip: [false],
      },
      {
        validators: [
          this.selectReturnDate('depart', 'return', 'isSingleTrip'),
          this.cannotTravelValidator(),
          this.cannotSelectDomesticAMTW2C(),
        ],
      },
    );
  }

  validatePromoCode(event: any) {
    if (event.target.value) {
      this.subscriptions.add(
        this.promotionService
          .validatePromoCode(event.target.value)
          .subscribe((promoCode) => {
            this.promoCodeMessage =
              promoCode.isValid === false
                ? 'You have entered an invalid Promo Code. Please enter a valid Promo Code to avail discount.'
                : `Promo Code ${
                    promoCode.promoCode
                  } is successfully applied. You have saved ${promoCode.discountRate.toFixed(
                    2,
                  )}% from this promo code.`;
          }),
      );
    } else {
      this.promoCodeMessage = '';
    }
  }

  setDepartRange() {
    const sydneyDate = new Date(
      new Intl.DateTimeFormat('en-US', { timeZone: 'Australia/Sydney' }).format(
        new Date(),
      ),
    );

    this.maxDepartDate.setDate(sydneyDate.getDate() + 548);

    this.maxDepartDate = new Date(
      this.maxDepartDate.getFullYear(),
      this.maxDepartDate.getMonth(),
      this.maxDepartDate.getDate(),
    );

    this.minDepartDate = new Date(
      sydneyDate.getFullYear(),
      sydneyDate.getMonth(),
      sydneyDate.getDate(),
    );
  }

  setReturnDateAndRange() {
    this.showRequiredReturnDateMultiError = false;
    if (this.userSelectedReturn === false) {
      // pre-fill return date
      this.returnDate = new Date(this.departureDate);
      this.returnDate.setDate(new Date(this.departureDate.getDate() + 15));
    }
    // Sets return date range as it depends on departure date
    this.maxReturnDate = new Date(this.departureDate);
    this.maxReturnDate.setFullYear(this.maxReturnDate.getFullYear() + 1);
    this.maxReturnDate = new Date(
      this.maxReturnDate.getFullYear(),
      this.maxReturnDate.getMonth(),
      this.maxReturnDate.getDate(),
    );

    this.minReturnDate = new Date(
      this.departureDate.getFullYear(),
      this.departureDate.getMonth(),
      this.departureDate.getDate(),
    );

    this.maxReturnDateMulti = new Date(this.departureDate);
    this.maxReturnDateMulti.setFullYear(
      this.maxReturnDateMulti.getFullYear() + 1,
    );
    this.maxReturnDateMulti = new Date(
      this.maxReturnDateMulti.getFullYear(),
      this.maxReturnDateMulti.getMonth(),
      this.maxReturnDateMulti.getDate(),
    );

    this.minReturnDateMulti = new Date(
      this.departureDate.getFullYear(),
      this.departureDate.getMonth(),
      this.departureDate.getDate(),
    );
  }

  parseEuropeanDate(input: string): Date {
    const parts = input.split('/');
    if (parts.length !== 3) {
      throw new Error('Invalid date format. Please use DD/MM/YYYY.');
    }
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Month is 0-indexed in JavaScript, so 1 needs to be subtracted
    const year = parseInt(parts[2], 10);

    const date = new Date(year, month, day);
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date. Please check the values provided.');
    }
    return date;
  }

  inputDepartValidation(event: any) {
    this.showRequiredReturnDateMultiError = false;
    let departureDate = event.target.value;

    if (departureDate != null && departureDate != '') {
      let isValidDate = moment(departureDate, 'DD/MM/YYYY', true).isValid();
      if (!isValidDate) {
        this.singleTripForm.controls['depart'].setErrors({
          invalidInputDepart: 'Please enter a valid date format (dd/mm/yyyy).',
        });
        this.multiTripForm.controls['depart'].setErrors({
          invalidInputDepart: 'Please enter a valid date format (dd/mm/yyyy).',
        });
        this.returnDate = '';
        this.returnDateMulti = '';
      } else {
        // covers scenario where user types in date return date range is set
        departureDate = this.parseEuropeanDate(departureDate);
        departureDate = new Date(departureDate);

        // Typing a date that is in the past
        if (departureDate < this.minDepartDate) {
          this.singleTripForm.controls['depart'].setErrors({
            departDateBeforeToday:
              'Departure Date cannot be date before current date.',
          });
          this.multiTripForm.controls['depart'].setErrors({
            departDateBeforeToday:
              'Departure Date cannot be date before current date.',
          });
          this.returnDate = '';
          this.returnDateMulti = '';
        }
        // Typing in a date that is more than 18 months from today
        else if (departureDate > this.maxDepartDate) {
          this.singleTripForm.controls['depart'].setErrors({
            departDateMoreThan18Months:
              'Departure Date can not be greater than 548 days from today.',
          });
          this.multiTripForm.controls['depart'].setErrors({
            departDateMoreThan18Months:
              'Departure Date can not be greater than 548 days from today.',
          });
          this.returnDate = '';
          this.returnDateMulti = '';
        } else {
          this.singleTripForm.controls['depart'].setErrors(null);
          this.multiTripForm.controls['depart'].setErrors(null);

          // Sets the return date and range
          // pre-fill return date
          if (this.userSelectedReturn === false) {
            this.returnDate = new Date(departureDate);
            this.returnDate.setDate(new Date(departureDate.getDate() + 15));
          }

          // Sets return date range as it depends on departure date
          this.maxReturnDate = new Date(this.departureDate);
          this.maxReturnDate.setFullYear(this.maxReturnDate.getFullYear() + 1);
          this.maxReturnDate = new Date(
            this.maxReturnDate.getFullYear(),
            this.maxReturnDate.getMonth(),
            this.maxReturnDate.getDate(),
          );

          this.minReturnDate = new Date(
            this.departureDate.getFullYear(),
            this.departureDate.getMonth(),
            this.departureDate.getDate(),
          );

          this.maxReturnDateMulti = new Date(this.departureDate);
          this.maxReturnDateMulti.setFullYear(
            this.maxReturnDateMulti.getFullYear() + 1,
          );
          this.maxReturnDateMulti = new Date(
            this.maxReturnDateMulti.getFullYear(),
            this.maxReturnDateMulti.getMonth(),
            this.maxReturnDateMulti.getDate(),
          );

          this.minReturnDateMulti = new Date(
            this.departureDate.getFullYear(),
            this.departureDate.getMonth(),
            this.departureDate.getDate(),
          );
        }
      }
    }
  }

  inputReturnValidation(event: any, tripType: any) {
    let returnDate = event.target.value;
    if (returnDate != '') {
      this.userSelectedReturn = true;
    }

    if (returnDate != null && returnDate != '') {
      let isValidDate = moment(returnDate, 'DD/MM/YYYY', true).isValid();
      if (!isValidDate) {
        if (tripType === 'singleTrip') {
          this.singleTripForm.controls['return'].setErrors({
            invalidInputReturn:
              'Please enter a valid date format (dd/mm/yyyy).',
          });
        } else if (tripType === 'multiTrip') {
          this.multiTripForm.controls['return'].setErrors({
            invalidInputReturnMulti:
              'Please enter a valid date format (dd/mm/yyyy).',
          });
        }
      } else {
        // covers scenario where user types in date return date range is set
        returnDate = this.parseEuropeanDate(returnDate);
        returnDate = new Date(returnDate);

        // Typing a date that is in the past
        this.minReturnDate.setHours(0, 0, 0, 0);

        if (
          returnDate < this.minReturnDate &&
          tripType === 'singleTrip' &&
          !this.singleTripForm.errors?.['returnBeforeDepartDate']
        ) {
          this.singleTripForm.controls['return'].setErrors({
            returnDateBeforeDepart:
              "Return Date can't be less than Departure date.",
          });
        } else if (
          returnDate < this.minReturnDateMulti &&
          tripType === 'multiTrip' &&
          !this.multiTripForm.errors?.['returnBeforeDepartDateMulti']
        ) {
          this.multiTripForm.controls['return'].setErrors({
            returnDateBeforeDepart:
              "Return Date can't be less than Departure date.",
          });
        }
        // For Single trip - Return date that is over a year from the departure
        else if (
          returnDate > this.maxReturnDate &&
          tripType === 'singleTrip' &&
          !this.singleTripForm.errors?.['returnGreaterThanOneYear']
        ) {
          this.singleTripForm.controls['return'].setErrors({
            returnDateMoreThan365Days:
              'Return Date cannot be more than 365 days from Departure date.',
          });
        } else if (
          returnDate > this.maxReturnDateMulti &&
          tripType === 'multiTrip' &&
          !this.multiTripForm.errors?.['returnDateMoreThan60Days']
        ) {
          this.multiTripForm.controls['return'].setErrors({
            returnDateMoreThan60Days:
              'Returning On date must be less than or equal to 60 days from Departure date.',
          });
        }
        // no errors
        else if (tripType === 'singleTrip') {
          this.singleTripForm.controls['return'].setErrors(null);
        } else if (tripType === 'multiTrip') {
          this.multiTripForm.controls['return'].setErrors(null);
        }
      }
    }
  }

  // Return date selected before depart date and return date is earlier than depart date
  selectReturnDate(from: string, to: string, isSingleTrip: string) {
    return (group: FormGroup): { [key: string]: any } | null => {
      const controlFrom = group.controls[from];
      const controlTo = group.controls[to];
      const isSingleTripControl = group.controls[isSingleTrip];
      if (
        !controlFrom ||
        !controlTo ||
        !controlFrom.value ||
        !controlTo.value
      ) {
        // If either control does not exist or does not have a value, validation is bypassed
        return null;
      }
      let isValidDate = moment(controlTo.value, 'DD/MM/YYYY', true).isValid();
      if (isValidDate) {
        const fromDate = new Date(controlFrom.value);
        const toDate = new Date(controlTo.value);

        let maxReturnDate = new Date(fromDate);
        maxReturnDate = new Date(
          maxReturnDate.setFullYear(fromDate.getFullYear() + 1),
        );

        let limitfromDate = new Date(fromDate);
        limitfromDate.setDate(limitfromDate.getDate() + 60);
        let isAMTReturnGreater60Days = toDate > limitfromDate;

        if (fromDate > toDate && fromDate && toDate) {
          if (isSingleTripControl.value) {
            return {
              returnBeforeDepartDate:
                "Return Date can't be less than Departure date.",
            };
          } else {
            return {
              returnBeforeDepartDateMulti:
                "Return Date can't be less than Departure date.",
            };
          }
        } else if (!isSingleTripControl.value && isAMTReturnGreater60Days) {
          return {
            returnDateMoreThan60Days:
              'Returning On date must be less than or equal to 60 days from Departure date.',
          };
        } else if (toDate > maxReturnDate && fromDate && toDate) {
          if (isSingleTripControl.value) {
            return {
              returnGreaterThanOneYear:
                'Return Date cannot be more than 365 days from Departure date.',
            };
          }
        } else if (
          fromDate.getDate() === toDate.getDate() &&
          fromDate.getMonth() === toDate.getMonth() &&
          fromDate.getFullYear() === toDate.getFullYear()
        ) {
          if (isSingleTripControl.value) {
            return {
              datesEqual: "Return Date can't be same as Departure Date.",
            };
          } else {
            return {
              datesEqualMulti: "Return Date can't be same as Departure Date.",
            };
          }
        }
      }
      return {};
    };
  }

  cannotTravelValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.selectedCountries && this.selectedCountries.length > 0) {
        let adviceMessage = {} as any;
        // Check if there are countries that is considered as sanctioned
        const cautionCountries = this.selectedCountries
          .filter(
            (country: any) =>
              country.allowTravel === false &&
              country.isCautionCountry === true,
          )
          .map((country: any) => country.countryName)
          .join(',');
        if (cautionCountries.length > 0) {
          adviceMessage.cautionCountry = `You are unable to complete your quote for the following destination(s): ${cautionCountries}`;
        }

        // Check if there are countries that is considered as do not travel
        this.countriesNotAllowedToTravel = this.selectedCountries
          .filter(
            (country: any) =>
              country.allowTravel === false && country.isDNTCountry === true,
          )
          .map((country: any) => country.countryName);

        if (this.countriesNotAllowedToTravel.length > 0) {
          adviceMessage.cannotTravelCountry = `Travel advice to ${this.countriesNotAllowedToTravel} has been updated to DO NOT TRAVEL by The Australian Department of Foreign Affairs and Trade. Our Policy excludes cover to any country with the advice of Do not travel and cannot offer you a policy to this destination. The Australian Department of Foreign Affairs and Trade.`;
        }
        return adviceMessage;
      }
      return null; // no errors
    };
  }

  cannotTravelDomesticInternational() {
    return (group: FormGroup): ValidationErrors | null => {
      if (this.selectedCountries && this.selectedCountries.length > 0) {
        let isDomestic = this.selectedCountries[0].isDomestic;
        // Check if there are countries that is considered as do not travel
        let validToTravel = this.selectedCountries.every(
          (country: any) => country.isDomestic === isDomestic,
        );
        if (!validToTravel)
          return {
            cannotTravelDomesticInternational:
              'We can not complete your request. You cannot mix Domestic and International travel on a single policy.',
          };
      }
      return null; // no errors
    };
  }

  cannotSelectDomesticAMTW2C() {
    return (group: FormGroup): ValidationErrors | null => {
      return null; // no errors
    };
  }

  patchForm() {
    this.singleTripForm.patchValue({
      travelDestination: this.selectedCountries,
    });
    this.multiTripForm.patchValue({
      travelDestination: this.selectedCountries,
    });
    this.addCustomPlaceHolder();

    // this.hideOverlay();//ADD THIS CALL IF YOU WISH TO CLOSE THE COUNTRY SELECTION UPON CLICK
  }

  hideOverlay() {
    const overlay = this.elRef.nativeElement.querySelector('.p-overlay');
    if (overlay) {
      overlay.style.display = 'none';
    }
  }

  onSingleTripClick() {
    this.patchForm();
    this.singleTripForm.patchValue({
      isSingleTrip: true,
      tripType: 'Single',
    });
    this.multiTripForm.patchValue({
      isSingleTrip: true,
      tripType: 'Single',
    });
  }

  getAgeMessage(traveller: any, field: any, message: any, displayMessage: any) {
    if (traveller == 'first_age' && field == 'first_age') {
      this.primaryAgeMessage = displayMessage == true ? message : '';
    } else if (traveller == 'second_age' && field == 'second_age') {
      this.secondaryAgeMessage = displayMessage == true ? message : '';
    }
  }

  setupFieldValidationTimer(form: any, isSingleTrip: boolean) {
    if (isSingleTrip) {
      this.subscriptions.add(
        form
          .get('age1')
          ?.valueChanges.pipe(debounceTime(800))
          .subscribe(() => {
            this.showErrorAge1 = form.get('age1')?.invalid;
          }),
      );

      this.subscriptions.add(
        form
          .get('age2')
          ?.valueChanges.pipe(debounceTime(800))
          .subscribe(() => {
            this.showErrorAge2 = form.get('age2')?.invalid;
          }),
      );
    } else {
      this.subscriptions.add(
        form
          .get('age1')
          ?.valueChanges.pipe(debounceTime(800))
          .subscribe(() => {
            this.showErrorAge1Multi = form.get('age1')?.invalid;
          }),
      );

      this.subscriptions.add(
        form
          .get('age2')
          ?.valueChanges.pipe(debounceTime(800))
          .subscribe(() => {
            this.showErrorAge2Multi = form.get('age2')?.invalid;
          }),
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onSingleTripSubmit(route?: string) {
    this.patchForm();
    if (this.singleTripForm.valid) {
      let quoteEnquiryObject = this.singleTripForm.value;
      quoteEnquiryObject.state = this.stateList.find(
        (state) => state.id == quoteEnquiryObject.state,
      );
      this.localCacheService.saveSessionStorage(
        'quoteEnquiry',
        quoteEnquiryObject,
      );
      const quoteDetail =
        this.localCacheService.getSessionStorage('quoteDetail');
      if (!quoteDetail?.quoteNumber) {
        this.subscriptions.add(
          this.quoteService
            .getInitialQuote(quoteEnquiryObject)
            .subscribe((response) => {
              if (response?.error && response.error) {
                throw response;
              } else {
                // console.log('initial quote response', response);
                this.localCacheService.saveSessionStorage(
                  'quoteDetail',
                  response,
                );
                this.localCacheService.removeSessionStorage('pricingDetail');
                this.router.navigate(['/quotes-steps-one']);
              }
            }),
        );
      } else {
        this.subscriptions.add(
          this.quoteService
            .submitQuote(quoteEnquiryObject, 'home')
            .subscribe((response: any) => {
              if (response?.error && response.error) {
                throw response;
              } else {
                // console.log('submit quote response', response);
                this.localCacheService.saveSessionStorage(
                  'quoteDetail',
                  response,
                );
                this.localCacheService.removeSessionStorage('pricingDetail');
                this.router.navigate(['/quotes-steps-one']);
              }
            }),
        );
      }
    } else {
      // console.log('Form is not valid');
      // console.log('this.singleTripForm', this.singleTripForm);
      this.singleTripForm.markAllAsTouched();
    }
  }

  onMultiTripSubmit() {
    this.patchForm();
    this.showRequiredReturnDateMultiError = false;
    if (this.multiTripForm.valid) {
      let quoteEnquiryObject = this.multiTripForm.value;
      quoteEnquiryObject.state = this.stateList.find(
        (state) => state.id == quoteEnquiryObject.state,
      );
      this.localCacheService.saveSessionStorage(
        'quoteEnquiry',
        quoteEnquiryObject,
      );
      const quoteDetail =
        this.localCacheService.getSessionStorage('quoteDetail');
      if (!quoteDetail?.quoteNumber) {
        this.subscriptions.add(
          this.quoteService
            .getInitialQuote(quoteEnquiryObject)
            .subscribe((response) => {
              // console.log('initial quote response', response);
              this.localCacheService.saveSessionStorage(
                'quoteDetail',
                response,
              );
              this.localCacheService.removeSessionStorage('pricingDetail');
              this.router.navigate(['/quotes-steps-one']);
            }),
        );
      } else {
        this.subscriptions.add(
          this.quoteService
            .submitQuote(quoteEnquiryObject, 'home')
            .subscribe((response) => {
              // console.log('submit quote response', response);
              if (!response.error) {
                this.localCacheService.saveSessionStorage(
                  'quoteDetail',
                  response,
                );
                this.localCacheService.removeSessionStorage('pricingDetail');
                this.router.navigate(['/quotes-steps-one']);
              }
            }),
        );
      }
      // this.router.navigate(['/quotes-steps-one']);
    } else {
      // console.log('Form is not valid');
      // console.log('this.multiTripForm', this.multiTripForm);
      this.multiTripForm.markAllAsTouched();
    }
  }

  onAMTSelect() {
    this.returnDateMulti = '';
    this.patchForm();
    this.multiTripForm.patchValue({
      isSingleTrip: false,
      tripType: 'Multiple',
    });
    this.singleTripForm.patchValue({
      isSingleTrip: false,
      tripType: 'Multiple',
    });
  }

  loadValidations() {
    const validationServiceSub = this.validationService
      //TO DO: CHANGE TO DYNAMIC HANDLING
      .clubValidation()
      .subscribe((response) => {
        this.validationsList = response;
        this.prepareErrorMessages();
        this.setDefaultValuesForValidationRules(response);
      });

    this.subscriptions.add(validationServiceSub);
  }

  prepareErrorMessages() {
    this.validationsList.forEach((validation) => {
      if (!this.errorMessages[validation.field]) {
        this.errorMessages[validation.field] = {};
      }

      validation.rules.forEach((rule: Rule) => {
        this.errorMessages[validation.field][rule.type] = rule.message;
      });
    });
  }

  validateAge(traveller: any, event: any) {
    this.subscriptions.add(
      this.validationService.clubValidation().subscribe((data) => {
        for (const validation of data) {
          for (const rule of validation.rules) {
            if (
              rule.type === 'min' &&
              event.target.value != '' &&
              event.target.value < rule.value &&
              validation.field == traveller
            ) {
              this.getAgeMessage(
                traveller,
                validation.field,
                rule.message,
                true,
              );
              break;
            } else if (
              rule.type === 'max' &&
              event.target.value > rule.value &&
              validation.field == traveller
            ) {
              this.getAgeMessage(
                traveller,
                validation.field,
                rule.message,
                true,
              );
              break;
            } else if (
              rule.type === 'required' &&
              event.target.value.length < 1 &&
              validation.field == 'first_age'
            ) {
              this.getAgeMessage(
                traveller,
                validation.field,
                rule.message,
                true,
              );
              break;
            } else {
              this.getAgeMessage(
                traveller,
                validation.field,
                rule.message,
                false,
              );
            }
          }
        }
      }),
    );
  }
  setDefaultValuesForValidationRules(validationsList: any[]) {
    validationsList.forEach((validation: any) => {
      if (validation.field === 'returnDateDefaultValue') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'defaultValue') {
            this.defaultReturnDateValueIncrement = rule.value;
            return;
          }
        });
      } else if (validation.field === 'first_age') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'min') {
            this.firstMinAge = rule.value;
          } else if (rule.type === 'max') {
            this.firstMaxAge = rule.value;
          }
        });
      } else if (validation.field === 'second_age') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'min') {
            this.secondMinAge = rule.value;
          }
          if (rule.type === 'max') {
            this.secondMaxAge = rule.value;
          }
        });
      } else if (validation.field === 'first_age_multi') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'min') {
            this.firstMinAgeMulti = rule.value;
          }
          if (rule.type === 'max') {
            this.firstMaxAgeMulti = rule.value;
          }
        });
      } else if (validation.field === 'second_age_multi') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'min') {
            this.secondMinAgeMulti = rule.value;
          }
          if (rule.type === 'max') {
            this.secondMaxAgeMulti = rule.value;
          }
        });
      } else if (validation.field === 'countries') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'cautionCountry') {
            this.cautionCountryMessage = rule.message;
          } else if (rule.type === 'cannotTravelDomesticInternational') {
            this.cannotTravelDomesticInternationalMessage = rule.message;
          } else if (rule.type === 'cannotSelectDomesticAMTW2C') {
            this.cannotSelectDomesticAMTW2CMessage = rule.message;
          } else if (rule.type === 'travelDestinationRequired') {
            this.travelDestinationRequiredMessage = rule.message;
          }
        });
      } else if (validation.field === 'depart') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'invalidInputDepart') {
            this.invalidInputDepartMessage = rule.message;
          } else if (rule.type === 'departDateMoreThan18Months') {
            this.departDateMoreThan18MonthsMessage = rule.message;
          } else if (rule.type === 'departDateBeforeToday') {
            this.departDateBeforeTodayMessage = rule.message;
          } else if (rule.type === 'required') {
            this.departDateRequiredMessage = rule.message;
          }
        });
      } else if (validation.field === 'return') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'invalidInputReturn') {
            this.invalidInputReturnMessage = rule.message;
          } else if (rule.type === 'invalidInputReturnMulti') {
            this.invalidInputReturnMultiMessage = rule.message;
          } else if (rule.type === 'returnDateMoreThan365Days') {
            this.returnDateMoreThan365DaysMessage = rule.message;
          } else if (rule.type === 'returnDateBeforeDepart') {
            this.returnDateBeforeDepartMessage = rule.message;
          } else if (rule.type === 'returnDateMoreThan60Days') {
            this.returnDateMoreThan60DaysMessage = rule.message;
          } else if (rule.type === 'returnBeforeDepartDateMulti') {
            this.returnBeforeDepartDateMultiMessage = rule.message;
          } else if (rule.type === 'datesEqualMulti') {
            this.datesEqualMultiMessage = rule.message;
          } else if (rule.type === 'returnBeforeDepartDate') {
            this.returnBeforeDepartDateMessage = rule.message;
          } else if (rule.type === 'returnGreaterThanOneYear') {
            this.returnGreaterThanOneYearMessage = rule.message;
          } else if (rule.type === 'datesEqual') {
            this.datesEqualMessage = rule.message;
          } else if (rule.type === 'required') {
            this.returnRequiredMessage = rule.message;
          }
        });
      } else if (validation.field === 'state') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'required') {
            this.stateRequiredMessage = rule.message;
          }
        });
      }
    });
    this.singleTripForm
      .get('age1')
      ?.addValidators([
        Validators.min(this.firstMinAge),
        Validators.max(this.firstMaxAge),
      ]);
    this.singleTripForm
      .get('age2')
      ?.addValidators([
        Validators.min(this.secondMinAge),
        Validators.max(this.secondMaxAge),
      ]);
    this.multiTripForm
      .get('age1')
      ?.addValidators([
        Validators.min(this.firstMinAgeMulti),
        Validators.max(this.firstMaxAgeMulti),
      ]);
    this.multiTripForm
      .get('age2')
      ?.addValidators([
        Validators.min(this.secondMinAgeMulti),
        Validators.max(this.secondMaxAgeMulti),
      ]);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateTooltipVisibility();
  }

  toggleTooltip() {
    this.isTooltipVisible = !this.isTooltipVisible;
  }

  private updateTooltipVisibility() {
    this.isTooltipVisible = window.innerWidth < 768;
    this.isTooltipVisibleTravDest = window.innerWidth < 768;
    this.isTooltipVisibleDep = window.innerWidth < 768;
  }

  private loadWordings() {
    this.wordingService.getWordings().subscribe((wordings) => {
      let link = '';
      let linkText = '';
      let disclaimer = '';

      wordings.forEach((wording) => {
        if (wording.field === 'disclaimer') {
          disclaimer = wording.value;
        }
        if (wording.field === 'fullDisclaimerLink') {
          link = wording.value;
        }
        if (wording.field === 'fullDisclaimerLinkText') {
          linkText = wording.value;
        }
      });
      let href = '';
      switch (this.activatedClub.toUpperCase()) {
        case 'RACV':
          this.loadPdsToDisclaimer(disclaimer, linkText);
          break;
      }
    });
  }
  loadPdsToDisclaimer(disclaimer: string, linkText: string) {
    const currentLatestPDS =
      this.localCacheService.getSessionStorage('lastestPDS');
    if (!currentLatestPDS) {
      this.pdsService.getLatestPDS().subscribe((data) => {
        const href = `<a rel="noopener noreferrer" style="color: #6b959c !important;" class="underline text-light" target="_blank" href=${data?.pds[0].previewUrl}><strong>${linkText}</strong></a>`;
        disclaimer = disclaimer.replace('##linkText##', href);
        this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(disclaimer);
      });
    } else {
      const href = `<a rel="noopener noreferrer" style="color: #6b959c !important;" class="underline text-light" target="_blank" href=${currentLatestPDS?.previewUrl}><strong>${linkText}</strong></a>`;
      disclaimer = disclaimer.replace('##linkText##', href);
      this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(disclaimer);
    }
  }

  searchFn(term: string, item: any) {
    if (term.length < 2) return false;
    return item.countryName.toLowerCase().startsWith(term.toLowerCase());
  }
  onModelChange(event: any) {
    this.selectedCountries = event;
    this.searchTerms = '';
    this.patchForm();
  }

  countrySearchFn(searchTerms: any) {
    this.searchTerms = searchTerms?.term;
  }

  handlecountrySearcBlur(event: any) {
    this.ngSelectIsFocused = false;
    if (event && event.target) {
      event.target.value = '';
    }
    this.searchTerms = '';
  }
}
