<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header
      [activeHeader]="'TravelAlerts'"
    ></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/racv/page-header/travel-alerts.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Travel Alerts</h1>
      <!-- <p>RACV makes family travel insurance easy! Compare our levels of cover and select the best family travel insurance for you.</p> -->
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <h1>Active Traveller Alerts</h1>
      <p>
        <span style="font-size: 16px"
          >Worldwide events may impact coverage for new travel insurance
          purchases, or affect your travel plans for policies already purchased.
          Please review all travel alerts related to your area of travel, and
          the important information contained in them.</span
        >
      </p>
      <ul
        class="active-travel-alerts-nav w-100"
        style="display: block !important"
      >
        <li class="nav-item">
          <a
            rel="noopener noreferrer"
            class="nav-link pt3"
            target="_blank"
            href="/covid-faqs"
            >New COVID-19 FAQs</a
          >
        </li>
        <li class="nav-item">
          <a
            rel="noopener noreferrer"
            class="nav-link pt3"
            target="_blank"
            href="/israel"
            >Israel and the Occupied Palestinian Territories</a
          >
        </li>
        <li class="nav-item">
          <a
            rel="noopener noreferrer"
            class="nav-link pt3"
            target="_blank"
            href="/israelfaqs"
            >FAQs for Israel and OPT</a
          >
        </li>
      </ul>
      <h1>Inactive Traveller Alerts</h1>
      <p>
        <span style="font-size: 16px"
          >The alerts below are alerts that we have published in the past that
          are no longer active.</span
        >
      </p>
      <ul
        class="inactive-travel-alerts-nav w-100"
        style="display: block !important"
      >
        <li class="nav-item">
          <a
            rel="noopener noreferrer"
            class="nav-link pt3"
            target="_blank"
            href="/donot-travel"
            >'Do Not Travel'
          </a>
        </li>
        <li class="nav-item">
          <a
            rel="noopener noreferrer"
            class="nav-link pt3"
            target="_blank"
            href="/covid-19"
            >COVID-19
          </a>
        </li>
        <li class="nav-item">
          <a
            rel="noopener noreferrer"
            class="nav-link pt3"
            target="_blank"
            href="/old-covid-faqs"
            >COVID-19 FAQs
          </a>
        </li>
        <li class="nav-item">
          <a
            rel="noopener noreferrer"
            class="nav-link pt3"
            target="_blank"
            href="/virgin-airline"
            >Virgin Airline
          </a>
        </li>
        <li class="nav-item">
          <a
            rel="noopener noreferrer"
            class="nav-link pt3"
            target="_blank"
            href="/china-iran"
            >China and Iran - Do Not Travel
          </a>
        </li>
        <li class="nav-item">
          <a
            rel="noopener noreferrer"
            class="nav-link pt3"
            target="_blank"
            href="/indonesia-volcano"
            >Indonesia’s Mt Agung volcano
          </a>
        </li>
        <li class="nav-item">
          <a
            rel="noopener noreferrer"
            class="nav-link pt3"
            target="_blank"
            href="/hongkong-protest"
            >Hong Kong Protests
          </a>
        </li>
        <li class="nav-item">
          <a
            rel="noopener noreferrer"
            class="nav-link pt3"
            target="_blank"
            href="/fiji-cyclone"
            >Fiji's Cyclone Sarai
          </a>
        </li>
      </ul>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
