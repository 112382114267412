<div class="step-four-page-container theme-steps">
  <racv-app-header></racv-app-header>
  <!-- Step Count  -->
  <app-step-bar
    [customStyles]="{ width: '100%' }"
    [activeStep]="'step4'"
  ></app-step-bar>

  <section class="quote selection">
    <div class="container mt-3 p-md-3 p-4">
      <div class="row justify-content-center">
        <div class="col-md-11 col-12 borderpre p-4">
          <div class="row justify-content-center">
            <div class="col-md-12 p-3">
              <div class="summary-title d-flex justify-content-between">
                <h4>Policy Details</h4>
                <h4>
                  <a routerLink="/quotes-steps-two" class="text-info">
                    <i class="fa-regular fa-pen-to-square"></i>
                  </a>
                </h4>
              </div>
            </div>

            <div class="col-md-6">
              <div class="summary-result bg-light p-3">
                <table class="w-100">
                  <tr>
                    <td class="text-secondary">Quote Number</td>
                    <td class="fw-bold">{{ policyDetails.quoteNumber }}</td>
                  </tr>
                  <tr>
                    <td class="text-secondary">Destination</td>
                    <td class="fw-bold">{{ policyDetails.destination }}</td>
                  </tr>
                  <tr>
                    <td class="text-secondary">Trip Type</td>
                    <td class="fw-bold">
                      {{
                        policyDetails.isSingleTrip
                          ? 'Single Trip'
                          : 'Annual Multi Trip'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-secondary">Travel Dates</td>
                    <td class="fw-bold">
                      {{ policyDetails.departDate }}
                      <span>
                        <img src="assets/images/racv/chevron_right.png" />
                      </span>
                      {{ policyDetails.returnDate }}
                      <span
                        *ngIf="policyDetails?.isSingleTrip"
                        class="text-gray ms-1"
                        >{{ policyDetails.duration }} days</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="text-secondary" style="vertical-align: top">
                      Cruise
                    </td>
                    <td class="fw-bold">
                      {{ policyDetails.isCruise }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-secondary" style="vertical-align: top">
                      Ski/Winter
                    </td>
                    <td class="fw-bold">
                      {{ policyDetails.isSki }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-secondary">Age of Travellers</td>
                    <td class="fw-bold">{{ policyDetails.ageOfTravellers }}</td>
                  </tr>
                  <tr>
                    <td class="text-secondary">No. Dependents</td>
                    <td class="fw-bold">{{ policyDetails.noOfDependents }}</td>
                  </tr>
                  <tr>
                    <td class="text-secondary align-top">Name of Travellers</td>
                    <td class="fw-bold" [ngClass]="'traveler-names'">
                      {{ policyDetails.nameOfTravelers }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="col-md-6">
              <div class="summary-result bg-light p-3">
                <table class="w-100">
                  <tr>
                    <td class="text-secondary">
                      {{ policyDetails.productAlias }} Policy
                    </td>
                    <td class="fw-bold">
                      $ {{ policyDetails.totalCoverPolicy | number : '1.2-2' }}
                    </td>
                  </tr>
                  <tr *ngIf="!policyDetails.isSingleTrip">
                    <td class="text-secondary mobtd">
                      {{ selectedCover?.selectedPrice?.multiTripLength }}
                      Max Length Trip
                    </td>
                    <td class="fw-bold">
                      $
                      {{
                        selectedCover?.selectedPrice?.multiTripLengthPremium
                          | number : '1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-secondary">
                      {{ selectedCover?.selectedPrice?.excess }} Policy Excess
                    </td>
                    <td class="fw-bold">
                      $ {{ policyDetails.policyExcess | number : '1.2-2' }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-secondary">Membership Discount</td>
                    <td class="fw-bold" *ngIf="policyDetails.memberDisc < 0">
                      - ${{ -policyDetails.memberDisc | number : '1.2-2' }}
                    </td>
                    <td class="fw-bold" *ngIf="policyDetails.memberDisc === 0">
                      $ 0.00
                    </td>
                  </tr>
                  <tr>
                    <td class="text-secondary">Cruise</td>
                    <td class="fw-bold">
                      $ {{ policyDetails.cruiseExpense | number : '1.2-2' }}
                    </td>
                  </tr>

                  <tr>
                    <td class="text-secondary">Ski/Winter Sports</td>
                    <td class="fw-bold">
                      $ {{ policyDetails.skiExpense | number : '1.2-2' }}
                    </td>
                  </tr>
                  <tr *ngIf="policyDetails.additionalMedicalExpense > 0">
                    <td class="text-secondary">Medical Conditions</td>
                    <td class="fw-bold">
                      $
                      {{
                        policyDetails.additionalMedicalExpense
                          | number : '1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr *ngIf="policyDetails.promoCodeDisc < 0">
                    <td class="text-secondary">Promo Discount</td>
                    <td class="fw-bold">
                      <span class="text-danger"
                        >-$
                        {{
                          -policyDetails.promoCodeDisc | number : '1.2-2'
                        }}</span
                      >
                      <span class="promo"
                        >Promo Code: {{ policyDetails.promoCode }}
                        <!-- <span class="close-promo bg-transparent">x</span> -->
                      </span>
                    </td>
                  </tr>
                  <tr *ngIf="policyDetails.memberDisc > 0">
                    <td class="text-secondary">Promo Discount</td>
                    <td class="fw-bold">
                      <span class="text-danger"
                        >-$
                        {{ policyDetails.memberDisc | number : '1.2-2' }}</span
                      >
                      <span class="promo"
                        >Member Number: {{ policyDetails.promoCode }}</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="text-secondary">GST</td>
                    <td class="fw-bold">
                      $ {{ policyDetails.gst | number : '1.2-2' }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-secondary">Stamp Duty</td>
                    <td class="fw-bold">
                      $ {{ policyDetails.stampDuty | number : '1.2-2' }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="col-md-6">&nbsp;</div>
            <div class="col-md-6">
              <div class="sub-total py-3">
                <h4 class="">
                  Total:
                  <b>${{ policyDetails.totalExpense | number : '1.2-2' }}</b>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="quote selection">
    <div class="container mt-3">
      <div class="row justify-content-center">
        <div class="col-md-11 col-12 borderpre p-3">
          <div class="row justify-content-center">
            <div class="col-md-12 p-3">
              <div
                style="text-align: right"
                *ngIf="
                  agentGuid &&
                  !(activatedClub == 'W2C' || activatedClub == 'RACQ')
                "
              >
                <span style="margin-right: 10px">
                  <button
                    class="btn"
                    [ngClass]="{
                      'btn-info': isOnlinePayment === 'Yes',
                      'btn-outline-info': isOnlinePayment != 'Yes'
                    }"
                    (click)="onPaymentTypeChange('Online')"
                  >
                    Online Payment
                  </button>
                </span>
                <button
                  class="btn"
                  [ngClass]="{
                    'btn-info': isOnlinePayment != 'Yes',
                    'btn-outline-info': isOnlinePayment === 'Yes'
                  }"
                  (click)="onPaymentTypeChange('Cash')"
                >
                  Cash Payment
                </button>
              </div>
              <div class="row justify-content-start">
                <!-- Cash Payment Widget-->
                <form [formGroup]="paymentForm">
                  <div
                    class="box-panel"
                    [ngStyle]="{
                      display: isOnlinePayment === 'No' ? 'block' : 'none'
                    }"
                  >
                    <div class="row">
                      <div class="col-12 col-lg-6 p-2">
                        <label class="text-secondary" for="recieptNo"
                          >Payment Receipt No</label
                        >
                        <input
                          placeholder="Enter Payment Receipt No"
                          class="p-inputtext p-component p-element form-control"
                          type="text"
                          id="recieptNo"
                          pInputText
                          maxlength="30"
                          name="recieptNo"
                          formControlName="paymentRecieptNo"
                        />
                        <ng-container
                          *ngIf="
                            paymentForm.get('paymentRecieptNo')?.invalid &&
                            paymentForm.get('paymentRecieptNo')?.touched
                          "
                        >
                          <small class="text-danger">
                            <span> Please enter payment receipt no. </span>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-lg-12 p-2">
                        <div class="action-button-wrapper mt-2">
                          <span style="margin-right: 10px">
                            <button
                              class="btn btn-info btn"
                              (click)="pay()"
                              [disabled]="!paymentForm.valid"
                              [ngClass]="{
                                'p-button-disabled': !paymentForm.valid
                              }"
                            >
                              Confirm &amp; Pay
                            </button>
                          </span>
                          <button
                            class="btn btn-outline-info btn"
                            (click)="showConfirm()"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <!-- Braintree widget -->
                <app-braintree-widget
                  *ngIf="isOnlinePayment === 'Yes'"
                  [totalAmount]="policyDetails.totalExpense"
                  [autoClub]="quoteDetail?.autoClub"
                  [quoteId]="quoteDetail?.quoteId"
                ></app-braintree-widget>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p-dialog
      [(visible)]="displayConfirmation"
      [modal]="true"
      [closable]="false"
      [dismissableMask]="true"
      header="Confirmation"
    >
      <div class="p-dialog-content">
        <p>Are you sure you want to cancel your payment?</p>
      </div>
      <div class="p-dialog-footer">
        <button
          class="btn btn-info btn m-1"
          label="Ok"
          (click)="cancelCashPayment()"
        >
          Ok
        </button>
        <button
          class="btn btn-outline-info btn m-1"
          label="Cancel"
          (click)="displayConfirmation = false"
        >
          Cancel
        </button>
      </div>
    </p-dialog>
    <p class="text-center mt-3 mb-3" *ngIf="activatedClub === 'RACV'">
      <strong>Need help? Call us on:</strong>
      <strong class="number text-md-end">
        <a
          class="text-gray"
          href="tel:13 13 29"
          (click)="sendAdobeNeedCallClickEvent()"
        >
          13 13 29</a
        >
      </strong>
    </p>
  </section>
  <app-racv-footer></app-racv-footer>
</div>
