<div class="card theme-search">
  <app-racv-common-header></app-racv-common-header>
  <section class="mainhero">
    <div class="container-fluid herooverlay"></div>
    <div class="container">
      <div class="herocontent row align-items-center text-center">
        <div class="col-md-12">
          <h1 class="display-5 mt-10 text-light herotitle">
            {{ titleText }}
          </h1>
        </div>
      </div>
      
  <app-country-search></app-country-search>
    </div>
  </section>
  <app-racv-footer></app-racv-footer>
</div>
