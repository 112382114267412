<section class="footer" [ngClass]="dynamicClassName">
  <div class="container-xl footer-top pb-4 mb-4">
    <div class="row ps-md-5 ps-2 pe-md-5 pe-2">
      <ul class="ft-links">
        <li>
          <a routerLink="/terms-and-conditions" target="_blank" (click)="sendAdobeFooterClickEvent('Terms and Conditions')"
            >Terms and Conditions</a
          >
          <span class="d-none d-md-flex smallhide">|</span>
        </li>
        <li>
          <a routerLink="/privacy-policy" target="_blank" (click)="sendAdobeFooterClickEvent('Privacy Policy')">Privacy Policy</a>
          <span class="d-none d-md-flex smallhide">|</span>
        </li>
        <li>
          <a routerLink="/refunds" target="_blank" (click)="sendAdobeFooterClickEvent('Refunds Notice')">Refunds Notice</a>
          <span class="d-none d-md-flex smallhide">|</span>
        </li>
        <li>
          <a routerLink="/how-to-claim" target="_blank" (click)="sendAdobeFooterClickEvent('How to Claim')">How to Claim</a>
          <span class="d-none d-md-flex smallhide">|</span>
        </li>
        <li><a routerLink="/faqs" target="_blank" (click)="sendAdobeFooterClickEvent('FAQs')">FAQs</a></li>
      </ul>
    </div>
  </div>
  <div class="container-xl footer-bottom">
    <div class="row ps-md-5 ps-2 pe-md-5 pe-2">
      <p class="text-light text-small">
        Tokio Marine & Nichido Fire Insurance Co Ltd (ABN 80 000 438 291 AFSL
        246 548) is the issuer of RACV Travel Insurance. The 'RACV Travel
        Insurance - Combined Financial Services Guide and Product Disclosure
        Statement' (PDS) which includes the policy wording, is available <a
          href="{{ pdsLink }}"
          class="text-light"
          target="_blank"
          (click)="triggerSegmentEvent('pds_footer_3')"
          >here</a
        >. Any financial advice provided by RACV which is set out above is
        general in nature only, and does not take into account your objectives,
        financial situation or needs. Because of this, you should, before acting
        on the advice, consider the appropriateness of the advice, having
        regards to your objectives, financial situation and needs. You should
        consider the PDS in deciding whether to acquire, or to continue to hold
        the policies. Where it provides financial services in relation to RACV
        Travel Insurance (such as financial advice or arranging for the issue of
        insurance), Royal Automobile Club of Victoria (ABN 44 004 060 833 AR
        001243563) is acting as an Authorised Representative of the issuer of
        the insurance, Tokio Marine & Nichido Fire Insurance Co., Ltd. (ABN 80
        000 438 291 AFSL 246 548). Whilst the PDS outlines the Terms and
        Conditions of these products, the TMD’s outline the intended class of
        customers that comprise the target market for these travel products.
        This will allow you to consider which products best suit your
        objectives, financial situation and needs and consider the products
        appropriateness to your personal circumstances. TMD’s also outline
        matters involving the distribution and the review of these products. The
        TMD’s for each travel product can be found in <a
          href="{{ tmdLink }}"
          class="text-light"
          target="_blank"
          (click)="triggerSegmentEvent('tmd_footer_3')"
          >here</a
        >.
      </p>
    </div>
  </div>
</section>
