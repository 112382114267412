import { Component } from '@angular/core';
import { RacvFooterComponent } from '../../../components/racv-footer/racv-footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { RacvCommonHeaderComponent } from '../../../components/racv-common-header/racv-common-header.component';
import { RouterLink } from '@angular/router';
import { WordingService } from '../../../services/wording.service';
import { Title } from '@angular/platform-browser';
import { PDSService } from '../../../services/pds.service';
import { LocalCacheService } from '../../../services/localCache.service';

@Component({
  selector: 'app-faqs',
  standalone: true,
  imports: [
    RacvFooterComponent,
    StaticSearchComponent,
    RacvCommonHeaderComponent,
    RouterLink,
  ],
  templateUrl: './faqs.component.html',
  styleUrl: './faqs.component.scss',
})
export class FaqsComponent {
  activatedClub: string = '';
  customWordings: any = {};
  pdsLink: string = '';
  constructor(
    private wordingService: WordingService,
    private titleService: Title,
    private pdsService: PDSService,
    private localCacheService: LocalCacheService,
  ) {
    this.titleService.setTitle('FAQs');
  }

  ngOnInit(): void {
    this.loadWordings();
    const currentLatestPDS = this.localCacheService.getSessionStorage('lastestPDS');
    if (!currentLatestPDS) {
      this.pdsService.getLatestPDS().subscribe((data) => {
        if (data && data.pds && data.pds.length > 0) {
          this.pdsLink = data.pds[0].previewUrl;
        }
      });
    } else {
      this.pdsLink = currentLatestPDS?.previewUrl;
    }
  }

  private loadWordings() {
    this.wordingService.getWordings().subscribe((wordings) => {
      wordings.forEach((wording) => {
        if (wording.field === 'app-faqs') {
          this.customWordings = wording.values;
        }
      });
    });
  }

  toggleCollapse(event: Event, index: string): void {
    const button = event.target as HTMLElement;
    const element = document.getElementById(index);
    if (element) {
      const isExpanded = element.classList.contains('show');
      element.classList.toggle('show', !isExpanded);
      button.classList.toggle('collapsed', isExpanded);
      button.setAttribute('aria-expanded', (!isExpanded).toString());
    }
  }
}
