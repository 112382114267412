import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, of, tap, switchMap, throwError } from 'rxjs';
import {
  MedicalAccept,
  MedicalPremium,
  MedicalDeclaration,
} from '../interfaces/medical';
import { GlobalService } from './global.service';
import { LocalCacheService } from './localCache.service';
import moment from 'moment';
import { environment } from '../../environments/environment';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { ConfigService, KvResponse } from './config.service';

@Injectable({ providedIn: 'root' })
export class MedicalService {
  private medicalUrl = `${environment.baseAPIUrl}quote/medical`; // URL to web api
  private decryptUrl =
    'https://gateway.verisk.com/rating/au/travel/calculation/decrypt/v2';
  private veriskReCalculateUrl =
    'https://gateway.verisk.com/rating/au/travel/calculation/rescore/v2';
  private refreshTokenUrl = `${environment.baseAPIUrl}quote/token/verisk/${environment.autoClub}`;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  isRefreshingToken = false;
  configSetting: KvResponse;

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    private localCacheService: LocalCacheService,
    private configService: ConfigService) {
      this.configSetting = this.configService.getConfig();
    }

  medicalDeclaration(parameters: MedicalDeclaration): Observable<any> {
    this.globalService.showLoading('medicalDeclaration');
    return this.http
      .post<any>(`${this.medicalUrl}/declaration`, parameters, this.httpOptions)
      .pipe(
        tap((_) => {
          this.globalService.hideLoading('medicalDeclaration');
          console.log('medical declaration');
        }),
        catchError(
          this.globalService.handleError<any>(
            'failed to save medical questionnaire',
            [],
          ),
        ),
      );
  }

  acceptMedical(parameters: MedicalAccept): Observable<any> {
    this.globalService.showLoading('acceptMedical');
    return this.http
      .post<any>(`${this.medicalUrl}/accept`, parameters, this.httpOptions)
      .pipe(
        tap((_) => {
          this.globalService.hideLoading('acceptMedical');
          console.log('accept medical');
        }),
        catchError(
          this.globalService.handleError<any>('failed to save medical', []),
        ),
      );
  }

  addMedicalPremium(parameters: MedicalPremium): Observable<any> {
    this.globalService.showLoading('addMedicalPremium');
    return this.http
      .post<any>(`${this.medicalUrl}/premium`, parameters, this.httpOptions)
      .pipe(
        tap((_) => {
          this.globalService.hideLoading('addMedicalPremium');
          console.log('add medical premium');
        }),
        catchError(
          this.globalService.handleError<any>(
            'failed to add medical premium',
            [],
          ),
        ),
      );
  }

  decryptXMLData(): Observable<any> {
    const details = this.globalService.getCurrentAssessmentDetails();
    const xmlResult = details?.xmlResult;
    if (!xmlResult) return of('');
    const decrypthttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + this.localCacheService.getLocalStorage('gatewayToken'),
        'X-Vrr-Auth': this.configSetting.vrrInternal,
      }),
    };
    const parameter = {
      EncryptedXml: xmlResult,
    };
    return this.http
      .post<any>(this.decryptUrl, parameter, decrypthttpOptions)
      .pipe(
        tap((_) => {
          console.log('decrypt data: ', _);
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            return this.handle401Error(parameter);
          } else {
            return throwError(error);
          }
        }),
      );
  }

  buidMedicalVeriskUserSettings(result: any = ''): any {
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    const currentAssessment =
      this.localCacheService.getSessionStorage('currentAssessment');
    let age = 0;
    if (currentAssessment === 'primary' || currentAssessment === 'secondary') {
      age =
        currentAssessment === 'primary'
          ? quoteDetail.ages[0]
          : quoteDetail.ages[1];
    } else {
      const dependentIndex =
        this.localCacheService.getSessionStorage('dependentIndex');
      const dob =
        this.localCacheService.getSessionStorage('travelerDetails').dependents[
          dependentIndex
        ].dateOfBirth;
      age = this.calculateDepAge(dob);
    }

    let helixScore = this.findMaxRegionId(
      quoteDetail.destinations,
      quoteEnquiry.travelDestination,
    );

    return {
      $G_ShowClasses: '3,4',
      $G_IsAnnual: quoteDetail.isSingleTrip ? 0 : 1, //dynamic 0 for single trip
      $G_DirectlyLinked: true,
      $G_Regions: helixScore, //TODO dynamic??
      $G_AllowWidgetBMIMetricsControl: true,
      $G_Username: this.configSetting.vrrUserName, // dynamic as of now use this for testing BB3TokioMarineRACVAuTravelUAT / BB3TokioMarineW2CAusTravelUAT
      $G_AllowSavePartialDeclaration: 'false',
      $G_IndirectlyLinked: false,
      $G_LeadTime: 0,
      $G_CancellationCostId: 1,
      $G_IsWinterSport: age > 85 ? 1 : quoteDetail.isSking ? 2 : 1, //dynamic
      $G_IsMetric: true,
      $G_TripDuration: this.calculateDaysBetweenDates(quoteDetail), //dynamic diff from start date and to date
      $G_Age: age, //dynamic traveler age
      $G_Locale: 'Aus',
      // "$G_ResultFormat":"xml",
      $G_ScreeningData: result ?? '', //Dynamic initially it will be empty else supply xml
    };
  }
  veriskReCalculate(result: any): Observable<any> {
    const userSettings = this.buidMedicalVeriskUserSettings(result);
    let options = this.httpOptions;
    options.headers = options.headers.set(
      'Authorization',
      'Bearer ' + this.localCacheService.getLocalStorage('gatewayToken'),
    );
    options.headers = options.headers.set(
      'X-Vrr-Auth',
      this.localCacheService.getLocalStorage('bbJWT'),
    );
    return this.http
      .post<any>(this.veriskReCalculateUrl, userSettings, options)
      .pipe(
        tap((_) => {
          console.log('recalculate data');
        }),
        catchError(
          this.globalService.handleError<any>('failed to recalculate data', []),
        ),
      );
  }

  private calculateDaysBetweenDates(quoteDetail: any) {
    const depDate = moment(quoteDetail.fromDate, 'DD/MM/YYYY').startOf('day');
    const rtnDate = moment(quoteDetail.toDate, 'DD/MM/YYYY').startOf('day');
    return rtnDate.diff(depDate, 'days');
  }

  private calculateDepAge(dateOfBirth: string) {
    console.log('dateOfBirth', dateOfBirth);
    const birthDate = moment(dateOfBirth, 'DD/mm/yyyy').toDate();
    console.log(
      'birthDate',
      birthDate,
      birthDate.getFullYear(),
      birthDate.getMonth(),
    );
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  private handle401Error(payload: any): Observable<any> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      return this.refreshToken().pipe(
        switchMap(() => {
          return this.decryptXMLData();
        }),
        catchError((err) => {
          this.isRefreshingToken = false;
          return throwError(err);
        }),
      );
    }
    return of();
  }

  private refreshToken(): Observable<any> {
    return this.http
      .post<any>(
        this.refreshTokenUrl,
        { grant_type: 'password' },
        this.httpOptions,
      )
      .pipe(
        switchMap((token: any) => {
          this.isRefreshingToken = false;
          localStorage.setItem('gatewayToken', token.access_token);
          localStorage.setItem('bbJWT', token.client_jwt);
          const currentTimestamp = Date.now();
          localStorage.setItem(
            'lastTokenRetry',
            (currentTimestamp + token.expires_in * 1000).toString(),
          );
          return of(token);
        }),
        catchError((err) => {
          this.isRefreshingToken = false;
          return throwError(err);
        }),
      );
  }

  getOfferStatusString(medicalInfo: any) {
    if (medicalInfo == null || medicalInfo == undefined) {
      return '';
    }
    if (
      (medicalInfo.status === 'Not Covered' &&
        medicalInfo.medicalConditions === '') ||
      medicalInfo.status === 'Pending'
    ) {
      return 'Offer Pending';
    } else if (
      medicalInfo.status === 'Not Covered' &&
      medicalInfo.medicalConditions !== ''
    ) {
      return 'Not Covered';
    }
    return 'Offer ' + medicalInfo.status;
  }

  saveTravelerDetails(travelerDetailsFormValue: any) {
    const originalTravelerDetails =
      this.localCacheService.getSessionStorage('travelerDetails') || {};
    let newTravelerDetails = travelerDetailsFormValue;
    if (newTravelerDetails.dependents?.length > 0) {
      newTravelerDetails.dependents.map((dependent: any, index: number) => {
        const dependentTravelerInfo = originalTravelerDetails?.dependents?.find((trv: any) =>
          trv.firstName === dependent.firstName && trv.lastName === dependent.lastName);
        if (dependentTravelerInfo) {
          dependent.travelerId = dependentTravelerInfo?.travelerId ?? '';
        }
      });
    }
    this.localCacheService.saveSessionStorage(
      'travelerDetails',
      newTravelerDetails,
    );
  }

  checkTravellers(group: FormGroup) {
    const adult1 = group.get('adult1')?.value;
    const adult2 = group.get('adult2') ? group.get('adult2')?.value : null;

    if (adult2) {
      // Convert all values to lowercase for case-insensitive comparison
      const a1FirstName = adult1.firstName
        ? adult1.firstName.toLowerCase()
        : '';
      const a1LastName = adult1.lastName ? adult1.lastName.toLowerCase() : '';

      const a2FirstName = adult2.firstName
        ? adult2.firstName.toLowerCase()
        : '';
      const a2LastName = adult2.lastName ? adult2.lastName.toLowerCase() : '';

      // Check if all fields for both travellers are initially blank
      if (
        a1FirstName === '' &&
        a1LastName === '' &&
        adult1.dateOfBirth === '' &&
        a2FirstName === '' &&
        a2LastName === '' &&
        adult2.dateOfBirth === ''
      ) {
        return null; // Skip validation since all fields are blank
      }

      // Proceed with the usual checks if the fields are not all blank
      if (
        a1FirstName === a2FirstName &&
        a1LastName === a2LastName &&
        adult1.dateOfBirth === adult2.dateOfBirth
      ) {
        return { sameDetailsError: true };
      }
    }
    return null;
  }

  emailMatchValidator(
    control: AbstractControl,
  ): { [key: string]: boolean } | null {
    const email = control.get('emailId');
    const confirmEmail = control.get('confEmailId');
    if (
      email &&
      confirmEmail &&
      confirmEmail.value &&
      confirmEmail.valid &&
      email.value !== confirmEmail.value
    ) {
      return { emailMismatch: true };
    }
    return null;
  }

  phoneNumberValidator(
    control: AbstractControl,
  ): Observable<ValidationErrors | null> {
    const phoneNumber = control.value;
    if (phoneNumber && (phoneNumber.length < 8 || phoneNumber.length > 10)) {
      return of({ phoneNumberInvalid: true });
    }
    return of(null);
  }
  nameShouldNotExceed50AndNotContainNumbers(
    travellerType: string,
    field: string,
  ) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value.trim())
        return {
          nameValidationMessage: `Please enter the ${field} of the ${travellerType}`,
        };

      if (control.value.trim().length > 50) {
        if (this.containsDecimalNumbers(control.value.trim())) {
          setTimeout(() => {
            let value = control.value.replace(/\d+/g, '');
            value = value.replace(/[^a-zA-Z-\s',’]/g, '');
            control.setValue(value);
          }, 150);
        }
        return {
          nameValidationMessage: `${field} cannot have numbers and cannot be longer than 50 characters.`,
        };
      }
      if (this.isNumber(control.value.trim())) {
        setTimeout(() => {
          let value = control.value.replace(/\d+/g, '');
          value = value.replace(/[^a-zA-Z-\s',’]/g, '');
          control.setValue(value);
          control.setErrors({
            nameValidationMessage: `${field} cannot have numbers and cannot be longer than 50 characters.`,
          });
        }, 150);
        return null;
      }

      if (
        this.containsDecimalNumbers(control.value.trim()) ||
        !this.isValidName(control.value.trim())
      ) {
        setTimeout(() => {
          let value = control.value.replace(/\d+/g, '');
          value = value.replace(/[^a-zA-Z-\s',’]/g, '');
          control.setValue(value);
        }, 150);
        return null;
      }
      return null;
    };
  }

  isValidName(str: string) {
    const regex = /^[a-zA-Z-\s',’]+$/;
    const testResult = regex.test(str);
    return testResult;
  }

  isNumber(str: any) {
    return !isNaN(str) && str.trim() !== '';
  }
  containsDecimalNumbers(str: string) {
    return /\d+(\.\d+)?/.test(str);
  }

  findMaxRegionId(selectedCountries: any[], countries: any[]) {
    let regionId = '0';
    let selectedCountryList: any[] = [];
    selectedCountries.forEach((code: any) => {
      if (countries) {
        let selectedCountry: any = countries.find(
          (c: { countryCode: any }) => c.countryCode == code.countryCode,
        );
        if (selectedCountry) {
          selectedCountryList.push({
            countryCode: selectedCountry.countryCode,
            countryName: selectedCountry.countryName,
            ratingRegionName: selectedCountry.ratingRegionName,
            helixScore: selectedCountry.helixScore,
            regionId: selectedCountry.regionId,
          });
        }
      }
    });

    regionId = this.getMaxHelixScore(selectedCountryList);
    return regionId;
  }

  getMaxHelixScore(selectedCountryList: any[]) {
    let helixScore = '0';
    let helixScoreArr = [];
    if (selectedCountryList.length > 0) {
      for (let val of selectedCountryList) {
        helixScoreArr.push(val.helixScore);
      }
    }
    helixScoreArr.sort(function (a, b) {
      return a - b;
    });
    helixScore = helixScoreArr[helixScoreArr.length - 1];
    return helixScore;
  }
}
