<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header [activeHeader]="'HowToClaim'"></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img src="assets/images/racv/page-header/how-to-claim.jpg" alt="" class="w-100 page-title-image" />
    <div class="page-header-title-caption">
      <h1>How to Claim</h1>

    </div>
  </section>

  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="inner-page-content">
      <div class="container">
        <h2 class="lead">In an emergency</h2>
        <p>When you are travelling, our emergency medical assistance teams can help you anywhere in the world by simply
          calling them. They are there for you, 24 hours a day, able to help with emergencies and advice.</p>
        <p>
          Our &nbsp;team, with over 50 years of experience, are only a phone call away. Call them on
          <a href="tel:+61280551699">+61 2 8055 1699</a>
          (the insurer will accept reverse charges from the overseas operator).
        </p>
        <h2>Call Us</h2>
        <p>
          Please contact our claims team on 1300 207 387 and we will happily take your details to lodge a claim and
          guide you through the claim process.
        </p>
        <h2>Lodge a Claim Online</h2>
        <p>
          The quickest way to lodge a claim on your RACV Travel Insurance policy is using our online claim lodgement
          which is available 24/7. Follow these easy steps to lodge your claim online:
        </p>
        <ol>
          <li>&nbsp;You will first need to register by utilising the email address you used to purchase your policy</li>
          <li>&nbsp;Once registered you will receive an email to guide you to setup your password</li>
          <li>&nbsp;If you have previously registered a claim just login using your email and password</li>
          <li>&nbsp;Once you have logged in, complete your claim details by answering all sections</li>
          <li>&nbsp;Once submitted, your claim will be assessed within 10 working days and we will let you know if the
            insurer requires any further information/documentation from you.</li>
        </ol>
        <p>
          <br>
          <a href="https://racv-onlineclaim.tmnfatravelinsurance.com.au/Account/Register" target="_new"><span
              style="text-decoration: underline;"><strong>Register</strong></span></a>
          or
          <a href="https://racv-onlineclaim.tmnfatravelinsurance.com.au/Account/Login" target="_new"><span
              style="text-decoration: underline;"><strong>Login</strong></span></a>
        </p>
        <h2>Alternatively for general customer service you can:</h2>
        <ul>
          <li>
            Call us on
            <a href="tel:131329"><strong>13 13 29</strong></a>
          </li>
          <li>
            Email us at
            <a href="mailto:care@racv.com.au"><strong><span
                  style="text-decoration: underline;">{{'care@racv.com.au'}}</span></strong></a>
          </li>
        </ul>
        <h2>Interpreting Services</h2>
        <p>We are pleased to offer assistance in the following ways if you need help understanding any aspect of your
          travel insurance policy or claim in your language.</p>
        <h2>Contact Translating and Interpreting Services</h2>
        <p>Translating and Interpreting Services (TIS) are available 24/7 and their interpreters speak over 160
          languages. They're available over the phone and in person. Book an <a rel="noopener noreferrer"
            href="https://www.tisnational.gov.au/" target="_blank"
            style="color: #0062b2; background: transparent;">appointment with TIS</a>, or call them on 131 450. This is
          a free service.</p>
      </div>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>